import React from 'react';
import PropTypes from 'prop-types';

import {PageWrapper} from '../components/templates';
import {SEO, ContactForm} from '../components/organisms';
import {LayoutContainer, Column} from '../components/layouts';
import {Icon, Divider} from '../components/common';
import LayoutGenerator from '../helpers/layoutGenerator';
import {RCDLStateContext} from '../contexts/RCDLcontext';
import typographyStyles from '../styles/typography.module.css';

/**
 * @description Page template for 'contact' page.
 * @param {object} pageContext Page data from gatsby queries.
 * @return {DOMElement} Contact us page React element.
 */
const Contact = ({pageContext}) => {
  return (
    <PageWrapper pageContext={pageContext}>
      <SEO title="Contact Us" />
      <LayoutContainer
        additionalClass="rc-text--center rc-padding-bottom--sm rc-padding-top--lg
          rc-padding-top--xl--mobile rc-margin-top--md--mobile">
        <Column>
          <h1 className="rc-alpha">Contact us</h1>
        </Column>
      </LayoutContainer>

      <LayoutContainer additionalClass="rc-max-width--lg rc-padding-bottom--xl">
        <Column additionalClass={typographyStyles.textCenterMobile}>
          <div>
            <h2 className="rc-beta">Get in touch</h2>
            <p className="rc-margin-bottom--lg--mobile">
              If you need more information, you&apos;d like to request an insight
              into a specific area of pet ownership or if there&apos;s
              anything we can do to help, get in touch.</p>
          </div>
          <ul className="rc-list rc-list--blank rc-list--align">
            <li>
              <Icon icon="email" additionalClass="rc-margin-right--xs"/>
              <a
                className="rc-styled-link"
                href="mailto:Daniela.Gallegos@royalcanin.com">Daniela.Gallegos@royalcanin.com</a>
            </li>
          </ul>
        </Column>
        <Column>
          <RCDLStateContext.Consumer>
            {(context) =>
              <ContactForm RCDLState={context} pageTitle={pageContext.title}/>
            }
          </RCDLStateContext.Consumer>
        </Column>
      </LayoutContainer>

      <Divider additionalClass="rc-margin-bottom--xl"/>
      <div className="rc-padding-bottom--lg"></div>

      <div className={typographyStyles.textCenterMobile}>
        <LayoutGenerator layouts={pageContext.layoutContainer} />
      </div>
    </PageWrapper>
  );
};

Contact.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Contact;
